@charset "UTF-8";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Regular.woff2") format("woff2"), url("./fonts/PPMori-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Extralight.woff2") format("woff2"), url("./fonts/PPMori-Extralight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Bold.woff2") format("woff2"), url("./fonts/PPMori-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: "PPMori", sans-serif;
}

/*--------------------------------------------------------------
# Config
--------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 1em;
  line-height: 1.35;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}
a {
  text-decoration: none;
}
a:focus,
a:hover,
a:active {
  outline: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 0;
}
dfn {
  font-style: italic;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q::before,
q::after {
  content: "";
}
small {
  font-size: 85%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
nav ul,
nav ol,
ul,
li {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
label {
  cursor: pointer;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-family: var(--font);
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
  box-shadow: none;
  appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}
input,
textarea,
select {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: transparent;
}
td {
  vertical-align: top;
}

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

.centered {
  text-align: center;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.has-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

:root {
  --white: #fff;
  --black: #3c3c3b;
  --black-v2: #000;
  --orange: #f05a28;
  --orange-light: #f08024;
  --orange-v2: #ff590b;
  --orange-v3: #ff9211;
  --red: #95120b;
  --green: #2f797a;
  --green-middle: #318c8c;
  --green-light: #7cc9c2;
  --grey-light: #e5e5e5;
  --grey-lighter: #f5f5f5;
  --green-v2: #005344;
  --green-v3: #cde966;
  --font: "PPMori", sans-serif;
  --transition: 0.4s ease;
}

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/

body {
  background-color: var(--grey-lighter);
  color: var(--black);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 400;
}

.scroll-disabled {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.wrap {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 60px;
  width: 100%;
}

.main {
  width: 100%;
  padding: 20px 0;
}

/*--------------------------------------------------------------
# Links and buttons
--------------------------------------------------------------*/
a,
button {
  appearance: none;
  outline: none;
}
@media only screen and (min-width: 992px) {
  a:focus,
  button:focus {
    outline: 1px dashed rgba(29, 71, 65, 0.2);
  }
}

button,
input[type="button"],
input[type="submit"] {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.btn {
  border: 1px solid;
  border-radius: 100px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 42px;
  padding: 0 24px;
  text-transform: uppercase;
  transition: var(--transition);
}

.btn--small {
  height: 30px;
  line-height: 30px;
  padding: 0 13px;
}

.btn--orange {
  background-color: var(--orange-v2);
  border-color: var(--orange-v2);
  color: var(--white);
  cursor: pointer;
}

.btn--orange:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--orange-v2);
}

.btn--black {
  background-color: var(--black-v2);
  border-color: var(--black-v2);
  color: var(--white);
}

.btn--black:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--black-v2);
}

.btn--green {
  background-color: var(--green-v2);
  border-color: var(--green-v2);
  color: var(--white);
}

.btn--green:disabled {
  background-color: var(--grey-light);
  border-color: var(--green);
  color: var(--white);
}

.btn--green:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--green);
}

.btn--red {
  background-color: var(--red);
  border-color: var(--red);
  color: var(--white);
}

.btn--red:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--red);
}

.btn--white {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--orange-v3);
}

.btn--white:hover {
  background-color: var(--orange-v3);
  border-color: var(--orange-v3);
  color: var(--white);
}

.btn--green-light {
  background-color: var(--green-light);
  border-color: var(--green-light);
  color: var(--white);
}

.btn--green-light:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--green-light);
}

.btn-back {
  color: var(--black);
  font-size: 12px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.btn-back::before {
  background-image: url(./images/ico-arrow-left-black.svg);
  background-size: 6px 9px;
  content: "";
  display: inline-block;
  height: 9px;
  margin: 0 9px 0 3px;
  width: 6px;
}

.btn-back:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 11px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
  }
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
input,
textarea {
  background: var(--white);
  border: 1px solid var(--black-v2);
  border-radius: 0;
  box-shadow: none;
  color: var(--black);
  font-family: var(--font);
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::placeholder,
textarea::placeholder {
  color: rgba(60, 60, 59, 0.5);
}

.form {
  text-align: left;
  width: 100%;
}

.form__row {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
}

.form__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.form__column .MuiAutocomplete-hasPopupIcon.css-rdk77t-MuiAutocomplete-root .MuiOutlinedInput-root {
  border: 1px solid var(--black-v2);
  border-radius: 100px;
}

.form__column .form__item,
.MuiAutocomplete-root {
  width: 400px !important;
  max-width: 100% !important;
  margin-bottom: 20px;
}

.form__column h4 {
  margin-bottom: 10px;
}

.form__row--fullwidth .form__item {
  width: 100%;
}

.form__item {
  margin-right: 1.55%;
  width: 32.3%;
}

.form__item:last-child,
.form__item:nth-child(3n) {
  margin-right: 0;
}

.form__item--required .form__label::after {
  color: var(--orange);
  content: "*";
}

.form__label {
  display: block;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 4px;
}

.form__item-error {
  color: var(--red);
  font-size: 12px;
  font-weight: 500;
}

.form__input {
  height: 48px;
  border-radius: 24px;
  padding: 0 24px;
  border: 1px solid var(--black-v2);
}

.form__input[disabled] {
  background-color: var(--grey-light);
}

.form__dropdown {
  position: relative;
  width: 100%;
}

.form__dropdown--active .form__dropdown-btn--active::after {
  transform: translateY(-50%) rotate(180deg);
}

.form__dropdown--active .form__dropdown-list {
  display: block;
}

.form__dropdown--error .form__dropdown-btn::placeholder {
  color: var(--red);
}

.form__dropdown-btn {
  background-color: var(--white);
  border: 1px solid var(--black-v2);
  border-radius: 100px;
  color: var(--black);
  font-family: var(--font);
  font-size: 16px;
  overflow: hidden;
  padding: 13px 40px 14px 13px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form__dropdown-btn--placeholder {
  color: rgba(60, 60, 59, 0.8);
}

.form__dropdown-btn::after {
  background-image: url("./images/ico-chevron-down.svg");
  background-size: 16px 11px;
  content: "";
  height: 11px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform var(--transition);
  width: 16px;
}

.form__dropdown-list {
  background-color: var(--white);
  border: 1px solid var(--black-v2);
  border-radius: 25px;
  margin-top: 10px;
  border-top: 0;
  display: none;
  left: 0;
  max-height: 400px;
  overflow: auto;
  padding: 1px 18px 3px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 5;
}

.form__dropdown-item {
  border-bottom: 1px solid var(--grey-light);
  cursor: pointer;
  font-size: 16px;
  padding: 14px 0 12px;
  transition: color var(--transition);
  width: 100%;
}

.form__dropdown-item:last-child {
  border-bottom: 0;
}

.form__dropdown-item:hover,
.form__dropdown-item--selected {
  color: var(--orange);
}

.form__dropdown-item-multi {
  padding-left: 26px;
  position: relative;
}

.form__dropdown-item-multi::before {
  top: 16px;
}

.form__dropdown-item-multi.form__dropdown-item--selected::after {
  left: 2px;
  top: 19px;
}

.form__dropdown-item-multi::before,
.form__checkbox-label::before {
  border: 1px solid var(--black);
  border-radius: 2px;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  width: 16px;
}

.form__dropdown-item-multi.form__dropdown-item--selected::after,
.form__checkbox-input:checked ~ .form__checkbox-label::after {
  background-image: url("./images/ico-checkmark.svg");
  background-size: 12px 10px;
  content: "";
  height: 10px;
  position: absolute;
  width: 12px;
}

.form__checkbox {
  position: relative;
  width: 100%;
}

.form__checkbox:first-of-type {
  padding-top: 6px;
}

.form__checkbox-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.form__checkbox-input:checked ~ .form__checkbox-label::after {
  left: 2px;
  top: 9px;
}

.form__checkbox-label {
  display: inline-block;
  font-size: 16px;
  padding: 4px 0 3px 36px;
  position: relative;
}

.form__checkbox-label::before {
  top: 6px;
}

.form__radio {
  position: relative;
  width: 100%;
}

.form__radio:first-of-type {
  padding-top: 6px;
}

.form__radio-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.form__radio-input:checked ~ .form__radio-label::after {
  background-color: var(--orange-v3);
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 10px;
}

.form__radio-label {
  display: inline-block;
  font-size: 16px;
  padding: 4px 0 3px 36px;
  position: relative;
}

.form__radio-label::before {
  border: 1px solid var(--black);
  border-radius: 50%;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 16px;
}

.form__textarea {
  height: 140px;
  padding: 14px 20px;
  border: 1px solid var(--black-v2);
  border-radius: 16px;
}

.form__upload {
  border: 0;
  color: var(--black);
  font-size: 14px;
  padding: 0;
  position: relative;
}
.form__upload::-webkit-file-upload-button {
  background: var(--white);
  border: 1px solid var(--black);
  border-radius: 25px;
  color: var(--black);
  cursor: pointer;
  font-family: var(--font);
  font-size: 12px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  padding: 0 23px 0 42px;
  text-transform: uppercase;
}
.form__upload::before {
  background-image: url("./images/ico-attach.svg");
  background-size: 7px 13px;
  content: "";
  height: 13px;
  left: 28px;
  position: absolute;
  top: 19px;
  width: 7px;
}

.form__btn {
  margin-right: 15px;
}

.form-error,
.form-error .form__dropdown-btn {
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.form-error.form__checkbox-input ~ .form__checkbox-label,
.form-error.form__radio-input ~ .form__radio-label {
  color: var(--red);
}

.form-error.form__radio-input ~ .form__radio-label::before {
  border-color: var(--red);
}

.form-error::placeholder {
  color: var(--red);
}

.form__reorder {
  border-top: 2px solid var(--grey-light);
  width: 100%;
}

.form__reorder-listitem {
  border-bottom: 2px solid var(--grey-light);
  width: 100%;
}

.form__reorder-item {
  cursor: move;
  font-size: 16px;
  padding: 9px 55px 6px 20px;
  position: relative;
  width: 100%;
}

.form__reorder-item::after {
  background-image: url("./images/ico-reorder.svg");
  background-size: 12px 16px;
  content: "";
  height: 16px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}

@media only screen and (max-width: 767px) {
  .form__row {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .form__item {
    width: 100%;
  }
  .form__label {
    font-size: 15px;
  }
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--green-v2);
}

.login__box {
  background-color: var(--white);
  max-width: 570px;
  padding: 32px;
  text-align: center;
  width: 100%;
  border-radius: 24px;
}

.login__header {
  width: 100%;
}

.login__header-inner {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
}

.login__logo {
  width: 200px;
}

.login__heading {
  font-weight: 900;
  color: var(--white);
  position: relative;
}

.login__subheading {
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  margin-bottom: 24px;
}

.login__box .form__actions {
  text-align: center;
}

.login__form {
  padding: 0 25px;
}

.login__form .form__row {
  margin-bottom: 16px;
}

.login__box .form__item .form__label {
  font-weight: 900;
}

@media only screen and (min-width: 768px) {
  .login__box .form__item {
    position: relative;
  }

  .login__box .form__item .form__label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
  }

  .login__box .form__item input {
    padding-left: 110px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.header {
  align-items: stretch;
  background-color: var(--white);
  border-bottom: 2px solid var(--grey-light);
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  transition: var(--transition);
  transition-property: height, padding;
  width: 100%;
}

.header--orange .header__buttons {
  font-size: 0;
}

.header__logo {
  align-items: center;
  display: flex;
  font-family: var(--font);
  margin-bottom: -2px;
  padding: 10px 0;
  cursor: pointer;
  transition: padding var(--transition);
}

.header__logo-img {
  width: 120px;
}

.header__logo-text {
  color: var(--black-v2);
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  transition: font-size var(--transition);
}

.header__logo-text:not(:first-child) {
  padding-left: 11px;
  position: relative;
}

.header__logo-text:not(:first-child)::before {
  background-color: var(--black-v2);
  content: "";
  left: 0;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.header__actions {
  align-items: center;
  display: flex;
  padding: 10px 0;
}

.header__buttons:not(:first-child) {
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
}

.header__btn {
  margin-left: 20px;
}

.header__btn:first-of-type {
  margin-left: 0;
}

.header__nav-link {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  margin-left: 27px;
  transition: color var(--transition);
  position: relative;
  &::before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--orange-v2);
    transition: width var(--transition);
  }
}

.header__nav-link:first-child {
  margin-left: 0;
}

.header__nav-link--active {
  color: var(--orange);
}

.header__nav-link:hover {
  color: var(--orange-v2);
  &::before {
    width: 100%;
  }
}

.header__hamburger {
  display: none;
  height: 22px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: auto 20px;
  position: relative;
  width: 30px;
}

.header__hamburger::before,
.header__hamburger::after {
  background-color: var(--black);
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: transform var(--transition);
  width: 100%;
}

.header__hamburger::before {
  top: 0;
}

.header__hamburger::after {
  bottom: 0;
}

.header__hamburger__bar {
  background-color: var(--black);
  display: block;
  height: 2px;
  padding: 0 5px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .header__hamburger {
    display: block;
  }
  .header__hamburger--open .header__hamburger__bar {
    display: none;
  }
  .header__hamburger--open::before {
    transform: rotate(-45deg) translate(-7px, 7px);
  }
  .header__hamburger--open::after {
    transform: rotate(45deg) translate(-7px, -7px);
  }
  .header__actions {
    background-color: var(--white);
    border-bottom: 2px solid var(--grey-light);
    display: none;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 10;
  }
  .header__actions--open {
    display: flex;
    justify-content: space-between;
    .header__buttons {
      display: block;
    }
  }
  .header__buttons {
    align-items: center;
    display: none;
    margin-left: 0;
    padding-left: 0;
  }
  .header__buttons:not(:first-child)::before {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .header {
    padding: 0 50px;
  }

  .header__actions {
    .header__buttons {
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) {
  .login__header {
    margin-bottom: 30px;
  }
  .login__heading {
    padding-left: 20px;
  }
  .login__heading::before {
    content: "";
    width: 2px;
    height: 100%;
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 992px) {
  .header {
    padding-right: 0;
  }
  .header__logo {
    padding: 10px 20px;
  }
  .header__hamburger {
    display: block;
  }
  .header__actions {
    background-color: var(--white);
    border-bottom: 2px solid var(--grey-light);
    display: none;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 10;
    text-align: center;
  }
  .header__actions--open {
    display: block;
  }
  .header__buttons:not(:first-child) {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
  .header__buttons:not(:first-child)::before {
    display: none;
  }
  .header__nav {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    margin: 0 auto;
    width: fit-content;
  }
  .header__nav-link {
    display: block;
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
  .header__logo {
    width: 100%;
  }
  .header__logo-img {
    width: 70px;
  }
  .header__logo-text {
    font-size: 12px;
  }
  .header__actions {
    top: 60px;
    text-align: center;
  }
  .login__box {
    padding: 24px;
  }
  .login__header-inner {
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/

.dashboard {
  margin: 0 auto;
  max-width: 880px;
  padding: 30px 20px;
  width: 100%;
}

.dashboard__title {
  color: var(--black);
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
  width: 100%;
}

.dashboard__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.dashboard__item {
  display: flex;
  margin-bottom: 20px;
  margin-right: 2.4%;
  width: 47.6%;
}

.dashboard__item:nth-child(2n) {
  margin-right: 0;
}

.dashboard__link {
  align-items: center;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  font-size: 20px;
  min-height: 120px;
  padding: 20px 18px;
  border-radius: 16px;
  transition: var(--transition);
  transition-property: background, color;
  width: 100%;
}

.dashboard__link:hover {
  background-color: var(--orange-v2);
  color: var(--white);
}

.dashboard__link:hover .dashboard__image path {
  fill: var(--white);
}
.dashboard__link:hover .dashboard__image line {
  stroke: var(--white);
}

.dashboard__image {
  display: block;
  height: 60px;
  margin-right: 20px;
  width: 60px;
}

.dashboard__image path {
  transition: fill var(--transition);
}

.dashboard__link strong {
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .dashboard {
    padding: 40px 20px 20px;
  }
  .dashboard__title {
    font-size: 24px;
    margin-bottom: 26px;
  }
  .dashboard__item {
    margin: 0 0 10px;
    width: 100%;
  }
  .dashboard__link {
    font-size: 16px;
    min-height: 10px;
    padding: 10px;
  }
  .dashboard__image {
    transform: scale(0.7);
  }
}

/*--------------------------------------------------------------
# Intro
--------------------------------------------------------------*/

.intro {
  background-color: var(--white);
  align-items: center;
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.intro__title {
  font-size: 36px;
  font-weight: 100;
}

.intro__buttons {
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
}

@media only screen and (max-width: 992px) {
  .intro__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .intro .btn {
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 767px) {
  .intro__title:last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
# Manager empty
--------------------------------------------------------------*/

.manager__empty {
  padding-top: 20px;
  text-align: center;
  width: 100%;
}

.manager__empty-text {
  font-size: 18px;
  margin-bottom: 68px;
}

.manager__empty-image {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .manager__empty {
    padding-top: 30px;
  }
  .manager__empty-text {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .manager__empty-image {
    width: 130px;
  }
}

/*--------------------------------------------------------------
# Manager
--------------------------------------------------------------*/

.manager {
  margin-top: 20px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.manager__content {
  background-color: var(--white);
  width: 100%;
  padding: 0 20px;
}

.manager__content h2 {
  padding-top: 10px;
  padding-left: 10px;
  font-weight: 400;
}

.manager__content .form {
  padding: 20px 0;
}

.manager__content .form__label {
  margin-bottom: 10px;
}

.manager__content .form__row {
  margin-bottom: 20px;
}

.manager__content-modal {
  background-color: var(--white);
  border: 2px solid var(--grey-light);
  border-top: 0;
  padding: 40px 28px 28px;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.manager__title {
  background-color: var(--orange-v3);
  color: var(--white);
  display: flex;
  font-size: 18px;
  font-weight: 700;
  padding: 11px 30px;
  width: 100%;
}

.manager__title input {
  border: none;
}

.manager__title--compact {
  padding: 0;
}

.manager__title-text {
  margin-right: auto;
  padding: 11px 30px;
}

.manager__title-input {
  border: none;
  width: 300px;
}

.manager__title-select {
  border-left: 0;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  font-size: 16px;
  padding: 0 20px;
  width: 300px;
  border: none;
  -webkit-appearance: none;
}

.manager__table-wrap {
  overflow: auto;
  width: 100%;
}

.manager__content .floors-order {
  border-radius: 0;
  box-shadow: none;
  width: 100%;
}

.manager__content .floors-order__heading {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
}

.manager__content .floors-order__list {
  width: 100%;
}

.manager__content .floors-order__item {
  background: var(--orange-v3);
  border: 1px solid var(--orange-v3);
  border-radius: 16px;
  color: var(--white);
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px 20px;
  transition: var(--transition);
  transition-property: background, border, color;
}

.manager__content .floors-order__item:hover {
  background: var(--white);
  border-color: var(--orange-v3);
  color: var(--orange-v3);
}

.manager__content .floors-order__item:hover .btn--white {
  background-color: var(--orange-v3);
  border-color: var(--orange-v3);
  color: var(--white);
}

.manager__content .css-10hburv-MuiTypography-root {
  font-family: var(--font);
  font-weight: 400;
}

.employee-statistics__bar {
  display: flex;
  position: relative;
}

.employee-statistics__bar .manager__title--compact {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.employee-statistics__bar .btn {
  border-radius: 0 16px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 992px) {
  .manager__content {
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .manager__title,
  .manager__title-text {
    font-size: 14px;
    padding: 10px 20px 8px;
  }
  .manager__title--compact {
    padding: 0;
  }
  .manager__title-input,
  .manager__title-select {
    font-size: 14px;
    padding: 0 10px;
  }
  .manager__title-input {
    width: 240px;
  }
  .manager__title-select {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .manager__title,
  .manager__title-text {
    font-size: 14px;
    padding: 8px 10px 6px;
  }
  .manager__title--compact {
    flex-wrap: wrap;
    padding: 0;
  }
  .manager__title-input,
  .manager__title-select {
    font-size: 14px;
    height: 36px;
    padding: 0 10px;
    width: 100%;
  }
  .manager__title-input:not(:last-child) {
    border-bottom: 0;
  }
  .employee-statistics__bar {
    flex-wrap: wrap;
  }
}

/*--------------------------------------------------------------
# Table
--------------------------------------------------------------*/

.table {
  width: 100%;
}

.table__head {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  text-align: left;
}

.table__cell {
  font-size: 16px;
  padding: 10px;
  vertical-align: middle;
}

tr.alternate-table-row:nth-child(even) {
  background-color: var(--grey-lighter);
}

.table__head--action {
  width: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .table__head,
  .table__cell {
    font-size: 14px;
    padding: 10px 0 8px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .table__head,
  .table__cell {
    font-size: 13px;
    padding: 10px 0 8px 10px;
  }
}

/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/

.loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;
}

.loader__dot {
  animation: loadingDots 0.6s infinite linear alternate;
  background-color: var(--orange);
  border-radius: 50%;
  height: 12px;
  margin: 0 7px;
  position: relative;
  transform: scale(1);
  width: 12px;
}

.loader__dot:first-child {
  animation-delay: 0s;
}

.loader__dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loader__dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loadingDots {
  0%,
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

/*--------------------------------------------------------------
# Editor table menu
--------------------------------------------------------------*/
.context-menu {
  background-color: var(--white);
  border: 1px solid var(--black-v2);
  border-radius: 16px;
  overflow: hidden;
  width: 250px;
}

.context-menu__header {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.context-menu__header svg {
  cursor: pointer;
}

.context-menu__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.context-menu__cta {
  color: var(--orange);
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.context-menu__group {
  border-top: 2px solid var(--grey-light);
  margin-bottom: 10px;
  padding-top: 8px;
  text-align: center;
  width: 100%;
}

.context-menu__group:last-child {
  margin-bottom: 0;
}

.context-menu__font-text {
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.context-menu__font-btn {
  background-color: var(--orange-v3);
  border-radius: 50%;
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  margin: 0 3px;
  width: 30px;
  height: 30px;
}

.context-menu__group--angle {
  display: flex;
}

.context-menu__angle-btn {
  font-size: 11px;
  width: 100%;
}

.context-menu__angle-input {
  font-size: 14px;
  padding: 0 10px;
  width: 75px;
  border: 1px solid var(--black-v2);
  border-radius: 25px;
  margin-right: 10px;
}

.context-menu__group--size {
  display: flex;
  justify-content: space-between;
}

.context-menu__size-item {
  width: 100%;
}

/*--------------------------------------------------------------
# Popup
--------------------------------------------------------------*/

.popup {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 14;
}

.popup__background {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.popup__content {
  background-color: var(--white);
  border: 2px solid var(--grey-light);
  left: 50%;
  max-height: calc(100vh - 40px);
  max-width: 720px;
  overflow: auto;
  padding: 60px;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
}

.popup__content::-webkit-scrollbar {
  display: none;
}

.popup__content--small {
  max-width: 400px;
  padding: 64px 30px 60px;
  border: none;
  border-radius: 16px;
}

.popup__content--medium {
  max-width: 720px;
  padding: 64px 30px 60px;
}

.popup__content--large {
  max-width: 900px;
  padding: 64px 30px 60px;
  max-height: calc(100vh - 40px);
}

.popup__close {
  background-image: url("./images/ico-close.svg");
  background-size: 18px 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 18px;
  width: 18px;
}

.popup__heading-plain {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
}

.popup__actions {
  text-align: center;
  width: 100%;
}

.popup__btn {
  margin: 0 8px;
}

.popup__wrapper {
  width: 100%;
}

.popup__title {
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 25px;
}

.popup__item {
  border-top: 2px solid var(--grey-light);
  display: flex;
  padding: 10px;
  width: 100%;
}

.popup__item:last-child {
  border-bottom: 2px solid var(--grey-light);
}

.popup__item-cell {
  font-size: 14px;
  width: 100%;
}

.popup__item-cell--head {
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .popup__content {
    max-width: calc(100% - 40px);
  }
}

/*--------------------------------------------------------------
# Statistics
--------------------------------------------------------------*/

.stats {
  width: 100%;
}

.stats__wrap {
  display: flex;
  gap: 20px;
}

.stats-sidebar {
  background-color: var(--white);
  flex-shrink: 0;
  height: calc(100vh - 170px);
  overflow: auto;
  padding: 20px;
  width: 324px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 16px;
}

.stats-sidebar::-webkit-scrollbar {
  display: none;
}

.stats-sidebar__list {
  width: 100%;
}

.stats-sidebar__item {
  width: 100%;
}

.stats-sidebar__btn {
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: inherit;
  padding: 10px 20px;
  position: relative;
  text-align: left;
  transition: var(--transition);
  width: 100%;
  background-color: var(--green-v3);
}

.stats-sidebar__btn::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transition);
}

.stats-sidebar__btn:not(.stats-sidebar__btn--children)::after {
  background: url(./images/ico-chevron-down.svg) no-repeat center center;
  background-size: 7px 10px;
  height: 10px;
  width: 10px;
  transform-origin: center center;
  transform: rotate(-90deg) translateX(50%);
}

.stats-sidebar__btn--children::after {
  background: url(./images/ico-chevron-down.svg) no-repeat center center;
  background-size: 20px 16px;
  height: 16px;
  width: 20px;
}

.stats-sidebar__btn--active::after {
  transform: scaleY(-1) translateY(50%);
}

.stats-sidebar__btn--children-active::after {
  background-image: url(./images/ico-arrows-fold.svg);
}

.stats-sidebar__content {
  display: none;
  padding: 19px 20px 3px;
  width: 100%;
}

.stats-sidebar__content--active {
  display: block;
}

.stats-sidebar__subitem {
  display: block;
  font-size: 14px;
  margin-bottom: 14px;
  text-align: left;
  transition: color var(--transition);
  width: 100%;
}

.stats-sidebar__subitem:hover {
  color: var(--orange);
}

.stats-sidebar__subitem--active {
  color: var(--orange);
  font-weight: 600;
}

.stats-panel {
  background-color: var(--white);
  display: none;
  flex-shrink: 0;
  height: calc(100vh - 170px);
  overflow: auto;
  width: 452px;
  border-radius: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stats-panel::-webkit-scrollbar {
  display: none;
}

.stats-panel--open {
  display: block;
}

.stats-panel__actions {
  border-bottom: 2px solid var(--grey-light);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 20px 0 11px;
  width: 100%;
}

.stats-panel__radio {
  margin-left: 16px;
  padding-left: 16px;
  position: relative;
}

.stats-panel__radio::before {
  background-color: var(--black);
  bottom: 8px;
  content: "";
  left: 0;
  position: absolute;
  top: 9px;
  width: 1px;
}

.stats-panel__radio:first-of-type {
  margin-left: 0;
  padding-left: 0;
}

.stats-panel__radio:first-of-type::before {
  display: none;
}

.stats-panel__radio-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.stats-panel__radio-input:checked ~ .stats-panel__radio-label::after {
  background-color: var(--orange);
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 10px;
}

.stats-panel__radio-label {
  display: inline-block;
  font-size: 16px;
  padding: 5px 0 5px 26px;
  position: relative;
}

.stats-panel__radio-label::before {
  border: 1px solid var(--black);
  border-radius: 50%;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 16px;
}

.stats-panel__submit {
  margin-left: auto;
}

.stats-panel__floor {
  margin-bottom: 23px;
  width: 100%;
}

.stats-panel__floor:last-child {
  margin-bottom: 10px;
}

.stats-panel__floor-title {
  background-color: var(--orange-v3);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
}

.stats-panel__floor-title-btn {
  color: var(--white);
  padding: 11px 30px;
  text-align: left;
  width: 100%;
}

.stats-panel__content--hide {
  display: none;
}

.stats-panel__floor-actions {
  border-bottom: 2px solid var(--grey-light);
  display: flex;
  margin-bottom: 15px;
  padding: 10px 20px;
  width: 100%;
}

.stats-panel__office-holder {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  width: 100%;
}

.stats-panel__office-holder:not(:last-child) {
  border-bottom: 2px solid var(--grey-light);
  margin-bottom: 15px;
}

.stats-panel__office-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
}

.stats-panel__office {
  margin-bottom: 15px;
  position: relative;
  width: 25%;
}

.stats-panel__office::before {
  background: url(./images/ico-floor.svg) no-repeat center center;
  background-size: 62px 47px;
  content: "";
  height: 47px;
  left: 50%;
  position: absolute;
  top: 25px;
  transform: translateX(-50%);
  width: 62px;
}

.stats-panel__office-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.stats-panel__office-input:checked ~ .stats-panel__office-label::before {
  background-color: var(--orange-v3);
}
.stats-panel__office-input:checked ~ .stats-panel__office-label::after {
  display: block;
}

.stats-panel__office-label {
  display: block;
  font-size: 14px;
  padding-top: 77px;
  position: relative;
  text-align: center;
}

.stats-panel__office-label::before,
.stats-panel__office-label::after {
  content: "";
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.stats-panel__office-label::before {
  border: 2px solid var(--orange-v3);
  border-radius: 50%;
  height: 18px;
  top: 0;
  width: 18px;
}

.stats-panel__office-label::after {
  background: url(./images/ico-checkmark-white.svg) no-repeat center center;
  background-size: 12px 10px;
  display: none;
  height: 10px;
  top: 4px;
  width: 12px;
}

.stats-results {
  background-color: var(--white);
  border-radius: 16px;
  display: none;
  height: calc(100vh - 170px);
  overflow: auto;
  padding: 20px;
  transition: padding var(--transition);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stats-results--company {
  width: 100%;
}

.stats-results::-webkit-scrollbar {
  display: none;
}

.stats-results--open {
  display: block;
}

.stats-results__item {
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
}

.stats-results__item:last-child {
  margin-bottom: 0;
}

.stats-results__title {
  color: var(--green-v2);
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}

.stats-results__floor {
  margin-bottom: 12px;
  width: 100%;
}

.stats-results__floor:last-child {
  margin-bottom: 0;
}

.stats-results__name {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 5px;
}

.stats-results__content {
  display: block;
  font-size: 16px;
  margin-bottom: 6px;
}

.stats-results__content:last-child {
  margin-bottom: 0;
}

.stats-results__general {
  padding-bottom: 10px;
  width: 100%;
}

.stats-results__general-content {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.stats-results__heading {
  background-color: var(--orange-v3);
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  padding: 8px 10px;
  text-align: center;
  width: 100%;
}

.stats-panel__download-all-link {
  background: url(./images/ico-download.svg) no-repeat right center;
  background-size: 12px 14px;
  color: var(--orange);
  font-size: 16px;
  font-weight: 600;
  margin-top: 3px;
  padding: 7px 21px 4px 0;
}

.stats-panel__download-all-link:hover {
  text-decoration: underline;
}

.stats-panel__office--download::before {
  top: -1px;
}

.stats-panel__download {
  display: block;
  color: var(--orange);
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px;
  padding-top: 50px;
  position: relative;
  text-align: center;
}

.stats-panel__download:hover {
  text-decoration: underline;
}

.stats-panel__download::after {
  background: url(./images/ico-download.svg) no-repeat right center;
  background-size: 10px 12px;
  content: "";
  display: inline-block;
  height: 10px;
  margin-left: 2px;
  width: 12px;
}

@media only screen and (max-width: 1200px) {
  .stats-sidebar__btn {
    font-size: 16px;
  }
  .stats-sidebar__content {
    padding: 14px 15px 3px;
  }
  .stats-sidebar__subitem {
    font-size: 13px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .stats-sidebar {
    width: 279px;
  }
  .stats-panel {
    width: 430px;
  }
  .stats-panel__floor-title {
    font-size: 16px;
  }
  .stats-panel__floor-title-btn {
    padding: 10px 20px;
  }
  .stats-panel__radio-label,
  .stats-results__content {
    font-size: 14px;
  }
  .stats-panel__office-label {
    font-size: 12px;
  }
  .stats-results {
    padding: 20px;
  }
  .stats-results__title {
    font-size: 22px;
  }
  .stats-results__name {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .stats__wrap {
    flex-wrap: wrap;
  }
  .stats-sidebar {
    height: auto;
    width: 40%;
  }
  .stats-sidebar__item:last-child .stats-sidebar__btn,
  .stats-sidebar__item:last-child .stats-sidebar__content {
    border-bottom: 0;
  }
  .stats-panel {
    height: calc(100vh - 230px);
    width: 57%;
  }
  .stats-results {
    border-right: 0;
    height: auto;
    padding: 20px;
  }
  .stats-results__item {
    margin-bottom: 20px;
  }
  .stats-results__title {
    font-size: 20px;
  }
  .stats-results__name {
    font-size: 18px;
  }
  .stats-results__content {
    font-size: 14px;
    margin-bottom: 0;
  }
  .stats-results--company {
    padding: 20px;
    width: 57%;
  }
}

@media only screen and (max-width: 767px) {
  .stats__wrap {
    flex-wrap: wrap;
  }
  .stats-sidebar {
    height: auto;
    margin: 20px 0;
    padding: 20px;
    width: 100%;
  }
  .stats-sidebar__item:last-child .stats-sidebar__btn,
  .stats-sidebar__item:last-child .stats-sidebar__content {
    border-bottom: 0;
  }
  .stats-sidebar__btn {
    font-size: 14px;
  }
  .stats-panel {
    border-right: 0;
    border-left: 0;
    height: auto;
    width: 100%;
  }
  .stats-panel__floor {
    margin-bottom: 15px;
  }
  .stats-panel__floor-title {
    font-size: 16px;
  }
  .stats-panel__floor-title-btn {
    padding: 5px 10px;
  }
  .stats-panel__radio-label {
    font-size: 14px;
  }
  .stats-panel__office-holder {
    padding: 0 20px;
  }
  .stats-panel__office-title {
    font-size: 14px;
    margin-left: 0;
  }
  .stats-panel__office {
    margin-bottom: 10px;
  }
  .stats-panel__office::before {
    background-size: 50px auto;
    height: 44px;
    top: 20px;
    width: 54px;
  }
  .stats-panel__office-label {
    font-size: 12px;
    padding-top: 68px;
  }
  .stats-panel__office-holder:not(:last-child) {
    margin-bottom: 10px;
  }
  .stats-results {
    border-right: 0;
    height: auto;
    padding: 20px;
  }
  .stats-results__item {
    margin-bottom: 20px;
  }
  .stats-results__title {
    font-size: 20px;
  }
  .stats-results__name {
    font-size: 16px;
  }
  .stats-results__content {
    font-size: 12px;
    margin-bottom: 0;
  }
  .stats-results--company {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# General statistics
--------------------------------------------------------------*/

.stats-general {
  padding-top: 49px;
  width: 100%;
}

.stats-general__list {
  align-items: center;
  border-bottom: 2px solid var(--grey-light);
  display: flex;
  flex-direction: column;
  margin-bottom: 29px;
  padding-bottom: 22px;
  text-align: center;
  width: 100%;
}

.stats-general__list:last-child {
  border-bottom: 0;
}

.stats-general__item {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  max-width: 500px;
  width: 100%;
}

.stats-general__title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1px;
}

/*--------------------------------------------------------------
# Control panel head
--------------------------------------------------------------*/
.panel-head {
  background-color: var(--white);
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 16px;
}

.panel-head__list {
  display: flex;
  gap: 20px;
}

.panel-head__item {
  align-items: center;
  display: flex;
  min-height: 46px;
  position: relative;
}

.panel-head__item .btn-back {
  border-radius: 100px;
  background-color: var(--black-v2);
  color: var(--white);
  line-height: 40px;
  padding: 0 24px;
  border: 1px solid var(--black-v2);
  transition: var(--transition);
}

.panel-head__item .btn-back::before {
  content: none;
}

.panel-head__item .btn-back:hover {
  background-color: var(--white);
  color: var(--black-v2);
  text-decoration: none;
}

.panel-head__item:last-child::after {
  display: none;
}

.panel-head__search {
  position: relative;
}

.panel-head__input {
  height: 50px;
  padding: 2px 40px 0 18px;
}

.panel-head__btn {
  height: 46px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 46px;
}

.panel-head__btn-image {
  height: 18px;
  width: 18px;
}

.panel-head__title {
  font-size: 20px;
  font-weight: 400;
}

.panel-head--page .wrap {
  display: flex;
  justify-content: space-between;
}

.panel-head--page .panel-head__item:not(:first-child) {
  padding-right: 0;
}

.panel-head--page .panel-head__item:not(:first-child)::after {
  display: none;
}

.panel-head--page .form__dropdown {
  min-width: 260px;
}

.panel-head__zooming {
  display: flex;
}

.panel-head__zooming-item {
  margin-left: 15px;
}

.panel-head__zoom {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border: 2px solid var(--black);
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767px) {
  .panel-head {
    border-bottom: 2px solid var(--grey-light);
    padding: 20px;
  }
  .panel-head__list {
    flex-wrap: wrap;
    gap: 10px;
  }
  .panel-head__item {
    min-height: 1px;
    padding-right: 0;
    width: 100%;
  }
  .panel-head__item::after {
    display: none;
  }
  .panel-head__item:last-child {
    margin-bottom: 0;
  }
  .panel-head__search {
    width: 100%;
  }
  .panel-head__input {
    height: 40px;
    width: 100%;
  }
  .panel-head__btn {
    height: 36px;
  }
  .panel-head__title {
    font-size: 18px;
    width: 100%;
  }
}

/*--------------------------------------------------------------
# svg editor
--------------------------------------------------------------*/
.svg {
  display: flex;
  flex-direction: column;
  margin-top: -40px;
  width: 100%;
}

.svg__btns {
  margin-left: auto;
}
.svg__btn {
  margin-left: 10px;
}

.svg__btn:first-child {
  margin-left: 0;
}

.svg__holder {
  background-color: var(--grey-lighter);
  border: 2px solid var(--grey-light);
  margin-top: 20px;
  width: 100%;
}

/*--------------------------------------------------------------
# Print Styles
--------------------------------------------------------------*/
@media print {
  body {
    background: #fff;
    color: #000;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  a:link,
  a:visited {
    background: #fff;
    color: #000;
    text-decoration: underline;
  }

  a:not([href="javascript:;"]):after {
    content: "[" attr(href) "]";
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background: none;
    color: #000;
  }

  .header,
  .intro,
  .manager__title,
  .form__row,
  .form__actions,
  .svg__btns {
    display: none;
  }
  .wrap {
    padding: 0;
  }
  .manager__content {
    border: 0;
    padding: 0;
  }
  .svg__holder {
    background: none;
    border: 0;
    margin: 0;
  }
}

/*--------------------------------------------------------------
# Other Styles
--------------------------------------------------------------*/

div#reservation-employee-input .MuiOutlinedInput-root {
  border-radius: 100px;
}
